import {
  RESET_LICENSE_FILTERS,
  SAVE_LICENCE_FILTERS,
  SET_CUSTOM_SEARCH_QUERY,
  SHOW_SAVE_LICENSE_TOAST
} from "./actions";
import {
  SET_LICENSES_LIST,
  SET_LICENSE_INSTANCES,
  SET_PASSWORD_CONFIRM_DIALOG
} from "../../../../data/main/apps/licenses/actions";
import map from "lodash/map";
import { Action } from "@/types";

const initialState = {
  licensesList: [],
  filters: {
    active: true,
    inactive: true,
    archived: false,
    license_type: ""
  },
  licenseInstancesList: [],
  showSavedLicenseToast: false,
  displayConfirmDialog: false,
  customSearchQuery: "",
  pass: "",
  headerItems: [
    {
      label: "Name",
      sortLabel: "assignee",
      style: { maxWidth: "500px" }
    },
    {
      label: "Status",
      style: { maxWidth: "70px" }
    },
    {
      label: "Channel",
      sortLabel: "channel_name",
      style: { maxWidth: "100px" }
    },
    {
      label: "Type",
      sortLabel: "license_type",
      style: { maxWidth: "40px" }
    },
    {
      label: "Created On",
      sortLabel: "grant_date",
      style: { maxWidth: "100px" }
    },
    {
      label: "Expiry Date",
      sortLabel: "expiration_date",
      style: { maxWidth: "130px" }
    },
    {
      label: "Installs",
      style: { maxWidth: "100px" }
    },
    {
      label: "Actions",
      style: { maxWidth: "80px" }
    }
  ],
  instanceHeaderItems: [
    {
      label: "Status",
      style: { maxWidth: "110px" }
    },
    {
      label: "First Seen",
      style: { maxWidth: "280px" }
    },
    {
      label: "Last Seen",
      style: { maxWidth: "280px" }
    },
    {
      label: "Current Version",
      style: { maxWidth: "100px" }
    },
    {
      label: "Network",
      style: { maxWidth: "60px" }
    },
    {
      label: "Details",
      style: { maxWidth: "40px" }
    }
  ],
  docBriefs: {
    customerRequired: `The name of your customer.`,
    licenseChannel: `Which channel(s) this license will install from. If you select more than one channel here,
      the Admin Console will prompt for which channel to install from.`,
    expiration: `The expiration policy defines what actions Replicated should take when the expiration
      date is reached.`,
    updatePolicy: `The update policy controls how updates will be installed. Manual (the default) will
      require that the "Install Update" button is pressed. When set to Automatic, Replicated
      will automatically install any update when released.`,
    clusteredInstall: `When enabled, the Cluster page in the Admin Console will show options to add
      additional nodes and run some containers on other servers.`,
    airgapDownload: `When enabled, new installations with this license will have an option to install from
      an air gap package or from a traditional online installation.`,
    requireActivation: `When enabled, any new installation with this license will send a one-time activation
      code to the email address you provide. This code is required for installation. This is
      recommended for all new installations.`,
    enableLdap: `When enabled, installations using this license will have the option to secure
      the Admin Console using LDAP credentials`,
    enableLdapAdvanced: `The advanced LDAP feature allows for multi-domain and multi-ou integration. Please see docs
      for more information.
      https://help.replicated.com/docs/packaging-an-application/ldap-integration/`,
    licenseType: `The type of this license. This is only used for reporting. Select "dev" for any internal-use
      licenses, "trial" for your customers who are on a trial, and "paid" for paid licenses.`,
    customFields: `Custom Fields are useful as a way to securely delivery customer-specific values or entitlements
      to the installation. For more information, see
      https://help.replicated.com/docs/kb/developer-resources/license-controlled-settings/`,
    v2SupportBundleEnabled: `We are changing the data structure of our support bundles. You can enable this on a per license 
      basis to begin learning the new structure. For more info, check out help.replicated.com.`
  }
};

export default function (state = initialState, action: Action = {}) {
  switch (action.type) {
    case SET_LICENSES_LIST: {
      const _licensesList = Object.assign({}, action.payload.licenses);
      return Object.assign({}, state, {
        licensesList: map(_licensesList, "id")
      });
    }
    case SET_LICENSE_INSTANCES:
      return Object.assign({}, state, {
        licenseInstancesList: map(action.payload, "InstanceId")
      });
    case SET_PASSWORD_CONFIRM_DIALOG:
      return Object.assign({}, state, {
        displayConfirmDialog: action.payload.display,
        pass: action.payload.password
      });
    case SHOW_SAVE_LICENSE_TOAST:
      return Object.assign({}, state, {
        showSavedLicenseToast: action.payload
      });
    case SET_CUSTOM_SEARCH_QUERY:
      return Object.assign({}, state, {
        customSearchQuery: action.payload
      });
    case SAVE_LICENCE_FILTERS:
      return Object.assign({}, state, {
        filters: action.payload
      });
    case RESET_LICENSE_FILTERS:
      return Object.assign({}, state, {
        filters: {
          active: true,
          inactive: true,
          archived: false,
          license_type: ""
        }
      });
    default:
      return state;
  }
}
