import { useMutation, useQuery } from "@tanstack/react-query";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as R from "remeda";

import { TagsResponse, ClusterVersionsResponse, VMVersionsResponse } from "@/types";
import { apiFetchVendor } from "@/utilities/VendorUtilities";
import { distros } from "./util";

export const ClusterContext = createContext(null);

interface NodeGroup {
  name?: string;
  instance_type: string;
  disk_gib: number;
  node_count: number;
  // max_node_count: number;
  // min_node_count: number;
}

interface CreateClusterPayload {
  name: string;
  kubernetes_distribution: string;
  license_id: string;
  kubernetes_version: string;
  instance_type: string;
  ttl: string;
  tags: object[];
  node_groups: NodeGroup[];
  ip_family: string;
}

interface CreateVMPayload {
  name: string;
  distribution: string;
  version: string;
  ttl: string;
  tags: object[];
  instance_type: string;
  count: number;
  disk_gib: number;
}

interface Tags {
  key: string;
  value: string;
}

export const ClusterProvider = ({ children }) => {
  // TODO: refactor this with useReducer
  const [clusterName, setClusterName] = useState("");
  const [clusterId, setClusterId] = useState("");
  const [vmId, setVMId] = useState("");
  const [ttl, setTtl] = useState("1h");
  const [vmTtl, setVMTtl] = useState("1h");
  const [diskSize, setDiskSize] = useState(50);
  const [instanceType, setInstanceType] = useState("");
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [maxDiskSize, setMaxDiskSize] = useState(250);
  const [versionsInitialized, setVersionsInitialized] = useState(false);
  const [vmVersionsInitialized, setVMVersionsInitialized] = useState(false);
  const [kubernetesDistro, setKubernetesDistro] = useState("");
  const [vmDistro, setVMDistro] = useState("");
  const [distroChanged, setDistroChanged] = useState(false);
  const [tagKey, setTagKey] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [tempTags, setTempTags] = useState([]);
  const [isCreateNewTag, setIsCreateNewTag] = useState(false);
  const [clusterTagError, setClusterTagError] = useState("");
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [hasSetDiskSize, setHasSetDiskSize] = useState(false);
  const [hasSetNodeCount, setHasSetNodeCount] = useState(false);
  const [isAutoScaling, setIsAutoScaling] = useState(false);
  const [nodeGroups, setNodeGroups] = useState([
    {
      name: "",
      instance_type: "",
      disk_gib: 50,
      node_count: 1
      // max_node_count: 1,
      // min_node_count: 0
    }
  ]);
  const [vmConfig, setVMConfig] = useState({
    instance_type: "",
    disk_gib: 50,
    count: 1
  });
  const [kurlVersion, setKurlVersion] = useState("");
  const [kubernetesVersion, setKubernetesVersion] = useState("");
  const [vmDistroVersion, setVMDistroVersion] = useState("");
  const initialKurlVersion = "latest";
  const [licenseId, setLicenseId] = useState("");
  const [channelSequence, setChannelSequence] = useState("");
  const [ipFamily, setIPFamily] = useState("ipv4");
  const history = useHistory();
  const isCloudProvider = ["gke", "eks", "aks", "oke"].includes(kubernetesDistro);
  const location = useLocation();

  // #region queries and mutations
  const useInstanceTypes = kubernetesDistro => {
    const { data: availableInstanceTypes } = useQuery({
      queryKey: ["cluster-instance-types"],
      queryFn: async () => {
        return (await apiFetchVendor("/cluster/available-instance-types")).json();
      },
      select: data => {
        return data?.instanceTypes;
      },
      // only enable request on cmx page or troubleshoot
      enabled:
        location.pathname.includes("compatibility-matrix") ||
        location.pathname.includes("troubleshoot") ||
        location.pathname.includes("bundles")
    });

    if (!kubernetesDistro || !availableInstanceTypes) {
      return {
        availableInstanceTypes: [],
        instanceTypeDistros: []
      };
    }

    const instanceTypeDistros = distros
      .map(distro => {
        if (Object.keys(availableInstanceTypes).includes(distro.instanceTypeKey)) {
          return distro.shortName;
        }
        return null;
      })
      .filter(Boolean);

    switch (kubernetesDistro) {
      case "eks":
        return {
          availableInstanceTypes: availableInstanceTypes.eks.options,
          defaultInstanceType: availableInstanceTypes.eks.default,
          instanceTypeDistros
        };
      case "gke":
        return {
          availableInstanceTypes: availableInstanceTypes.gke.options,
          defaultInstanceType: availableInstanceTypes.gke.default,
          instanceTypeDistros
        };
      case "aks":
        return {
          availableInstanceTypes: availableInstanceTypes.aks.options,
          defaultInstanceType: availableInstanceTypes.aks.default,
          instanceTypeDistros
        };
      case "oke":
        return {
          availableInstanceTypes: availableInstanceTypes.oke.options,
          defaultInstanceType: availableInstanceTypes.oke.default,
          instanceTypeDistros
        };
      case "openshift":
        return {
          availableInstanceTypes: availableInstanceTypes.openshift.options,
          defaultInstanceType: availableInstanceTypes.openshift.default,
          instanceTypeDistros
        };
      // this distro is for development only
      case "fake":
      case "embedded-cluster":
      case "k3s":
      case "rke2":
      case "kind":
      case "kurl":
        return {
          availableInstanceTypes: availableInstanceTypes.replicated.options,
          defaultInstanceType: availableInstanceTypes.replicated.default,
          instanceTypeDistros
        };
      default:
        return {
          availableInstanceTypes: [],
          instanceTypeDistros: []
        };
    }
  };
  const { availableInstanceTypes, defaultInstanceType, instanceTypeDistros } =
    useInstanceTypes(kubernetesDistro);
  
  const useVMInstanceTypes = vmDistro => {
    const availableVMInstanceTypes = {
      replicated: {
        default: "r1.small",
        options: [
            "r1.small",
            "r1.medium",
            "r1.large",
            "r1.xlarge",
            "r1.2xlarge"
        ]
    }  
    }
  
    // TODO: uncomment this once the vm instance types endpoint is available
    // const { data: availableVMInstanceTypes } = useQuery({
    //   queryKey: ["vm-instance-types"],
    //   queryFn: async () => {
    //     return (await apiFetchVendor("/cluster/available-instance-types")).json();
    //   },
    //   select: data => {
    //     return data?.instanceTypes;
    //   },
    //   // only enable request on cmx page or troubleshoot
    //   enabled:
    //     location.pathname.includes("compatibility-matrix/create-vm")
    // });

    if (!vmDistro || !availableVMInstanceTypes) {
      return {
        availableVMInstanceTypes: [],
        vmInstanceTypeDistros: []
      };
    }

    const vmInstanceTypeDistros = distros
      .map(distro => {
        if (Object.keys(availableVMInstanceTypes).includes(distro.instanceTypeKey)) {
          return distro.shortName;
        }
        return null;
      })
      .filter(Boolean);
    switch (vmDistro) {
      case "ubuntu":
        return {
          availableVMInstanceTypes: availableVMInstanceTypes.replicated.options,
          defaultVMInstanceType: availableVMInstanceTypes.replicated.default,
          vmInstanceTypeDistros
        };
      case "almalinux":
        return {
          availableVMInstanceTypes: availableVMInstanceTypes.replicated.options,
          defaultVMInstanceType: availableVMInstanceTypes.replicated.default,
          vmInstanceTypeDistros
        };
      // this distro is for development only
      case "fakevm":
        return {
          availableVMInstanceTypes: availableVMInstanceTypes.replicated.options,
          defaultVMInstanceType: availableVMInstanceTypes.replicated.default,
          vmInstanceTypeDistros
        };
      default:
        return {
          availableVMInstanceTypes: [],
          vmInstanceTypeDistros: []
        };
    }
  };
  const {
    availableVMInstanceTypes,
    defaultVMInstanceType,
    vmInstanceTypeDistros
  } =
    useVMInstanceTypes(vmDistro);

  const { data: tags } = useQuery({
    queryKey: ["tags"],
    queryFn: async (): Promise<TagsResponse> => {
      return (await apiFetchVendor("/cmx/tags")).json();
    },
    select: data => data.tags,
    // only enable request on cmx page or troubleshoot
    enabled:
      location.pathname.includes("compatibility-matrix") ||
      location.pathname.includes("troubleshoot") ||
      location.pathname.includes("bundles")
  });

  const { data: availableVersions = [] } = useQuery({
    queryKey: ["cluster-versions"],
    queryFn: async (): Promise<ClusterVersionsResponse> => {
      return (await apiFetchVendor("/cluster/versions")).json();
    },
    select: data => {
      return R.sortBy(
        data?.["cluster-versions"] || [],
        distro => distro.short_name
      ).map(({ versions, ...rest }) => ({
        ...rest,
        versions: versions.slice().reverse()
      }));
    },
    // only enable request on cmx page or troubleshoot
    enabled:
      location.pathname.includes("compatibility-matrix") ||
      location.pathname.includes("troubleshoot") ||
      location.pathname.includes("bundles")
  });

  const { data: availableVMVersions = [] } = useQuery({
    queryKey: ["vm-versions"],
    queryFn: async (): Promise<VMVersionsResponse> => {
      return (await apiFetchVendor("/vm/versions")).json();
    },
    select: data => {
      return R.sortBy(
        data?.["vm-versions"] || [],
        distro => distro.short_name
      ).map(({ versions, ...rest }) => ({
        ...rest,
        versions: versions.slice().reverse()
      }));
    },
    // only enable request on cmx page
    enabled:
      location.pathname.includes("compatibility-matrix/create-vm")
  });

  const {
    error: createClusterError,
    mutate: createCluster,
    isLoading: createClusterLoading,
    isError: isCreateClusterError,
    reset: resetCreateClusterMutation
  } = useMutation({
    mutationFn: async (payload: CreateClusterPayload) => {
      return (
        await apiFetchVendor("/cluster", {
          method: "POST",
          body: JSON.stringify(payload)
        })
      ).json();
    },
    onSuccess: data => {
      const { cluster } = data;
      setClusterId(cluster.id);
      clearForm();
      history.push("/compatibility-matrix/edit-cluster");
    }
  });

  const {
    error: createVMError,
    mutate: createVM,
    isLoading: createVMLoading,
    isError: isCreateVMError,
    reset: resetCreateVMMutation
  } = useMutation({
    mutationFn: async (payload: CreateVMPayload) => {
      return (
        await apiFetchVendor("/vm", {
          method: "POST",
          body: JSON.stringify(payload)
        })
      ).json();
    },
    onSuccess: data => {
      const { vms } = data?.vms || {};
      setVMId(vms?.[0]?.id);
      clearVMForm();
      history.push("/compatibility-matrix/edit-vm");
    }
  });

  const {
    data: estimateClusterCostData,
    error: estimateClusterCostError,
    mutate: estimateClusterCost,
    isLoading: estimateClusterCostLoading,
    isError: isEstimateClusterCostError,
    reset: resetEstimateClusterCostMutation
  } = useMutation({
    mutationFn: async (payload: CreateClusterPayload) => {
      return (
        await apiFetchVendor("/cluster?dry-run=true", {
          method: "POST",
          body: JSON.stringify(payload)
        })
      ).json();
    }
  });

  const {
    data: estimateVMCostData,
    error: estimateVMCostError,
    mutate: estimateVMCost,
    isLoading: estimateVMCostLoading,
    isError: isEstimateVMCostError,
    reset: resetEstimateVMCostMutation
  } = useMutation({
    mutationFn: async (payload: CreateVMPayload) => {
      return (
        await apiFetchVendor("/vm?dry-run=true", {
          method: "POST",
          body: JSON.stringify(payload)
        })
      ).json();
    }
  });
  // #endregion

  // #region handlers
  const addTag = () => {
    if (tagKey && tagValue) {
      const newTag = { key: tagKey, value: tagValue };
      setTempTags(prevTags => [...prevTags, newTag]);
      setTagKey("");
      setTagValue("");
      setIsCreateNewTag(false);
      setIsInputOpen(false);
    }
  };
  const removeTag = (tag: { key: string; value: string }) => {
    setTempTags(prevTags => prevTags.filter(prevTag => prevTag !== tag));
  };
  const clearForm = () => {
    setTempTags([]);
    setClusterName("");
    setVersionsInitialized(false);

    setDistroChanged(false);
    setKubernetesDistro(availableVersions?.[0]?.short_name);
    setLicenseId("");
    setKurlVersion(initialKurlVersion);
    setTtl("1h");
    setDiskSize(50);
    setHasAttemptedSubmit(false);
    setTagKey("");
    setTagValue("");
    setIsCreateNewTag(false);
    setHasSetDiskSize(false);
    setInstanceType("");
    setNodeGroups([
      {
        name: "",
        instance_type: "",
        disk_gib: 50,
        node_count: 1
        // max_node_count: 1,
        // min_node_count: 0
      }
    ]);
    setKubernetesVersion(availableVersions?.[0]?.versions?.[0]);
    resetEstimateClusterCostMutation();
    resetCreateClusterMutation();
  };
  
  // TODO: double check this
  const clearVMForm = () => {
    setVMVersionsInitialized(false);
    setTempTags([]);
    setVMTtl("1h");
    setVMDistro("");
    setInstanceType("");
    setVMConfig({
      instance_type: "",
      disk_gib: 50,
      count: 1
    });
    setKubernetesVersion(availableVersions?.[0]?.versions?.[0]);
    resetEstimateVMCostMutation();
    resetCreateVMMutation();
  };


  const canHaveNodeGroups = () => {
    switch (kubernetesDistro) {
      case "kind":
        return false;
      case "embedded-cluster":
      case "k3s":
      case "rke2":
      case "openshift":
      case "kurl":
      case "eks":
      case "gke":
      case "aks":
      case "oke":
      case "fake":
        return true;
      default:
        return false;
    }
  };

  //  #endregion

  // #region useEffects
  useEffect(() => {
    if (tagValue === "Create new value") {
      setTagValue("");
      setIsInputOpen(true);
    }
  }, [tagValue]);

  useEffect(() => {
    return () => {
      clearForm();
    };
  }, []);

  useEffect(() => {
    // clears the estimate cluster data when switching pages
    if (estimateClusterCostData) {
      resetEstimateClusterCostMutation();
    }
    if (createClusterError) {
      resetCreateClusterMutation();
    }
    if (createVMError) {
      resetCreateVMMutation();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!defaultInstanceType) return;

    setInstanceType(defaultInstanceType);

    if (canHaveNodeGroups()) {
      setNodeGroups(prevNodeGroups =>
        prevNodeGroups.map(node => ({ ...node, instance_type: defaultInstanceType }))
      );
    } else {
      setNodeGroups(prevNodeGroups =>
        prevNodeGroups.slice(0, 1).map(node => ({
          ...node,
          instance_type: defaultInstanceType
        }))
      );
    }
    const nodeCount = nodeGroups[0].node_count;
    if (isCloudProvider && !hasSetNodeCount) {
      setNodeGroups(prevNodeGroups =>
        prevNodeGroups.map(node => ({ ...node, node_count: 3 }))
      );
    }
    if (!isCloudProvider && nodeCount !== 1) {
      setNodeGroups(prevNodeGroups =>
        prevNodeGroups.map(node => ({ ...node, node_count: 1 }))
      );
    }
    if (isCloudProvider && !hasSetDiskSize) {
      setDiskSize(100);
    }
    if (!isCloudProvider && !hasSetDiskSize) {
      setDiskSize(50);
    }
  }, [kubernetesDistro, defaultInstanceType, isCloudProvider]);

  useEffect(() => {
    if (!versionsInitialized && availableVersions.length > 0) {
      setKubernetesDistro(availableVersions[0].short_name);
      setKubernetesVersion(availableVersions[0].versions[0]);
      setKurlVersion(initialKurlVersion);
      setVersionsInitialized(true);
    }
  }, [availableVersions]);

  useEffect(() => {
    if (!vmVersionsInitialized && availableVMVersions.length > 0) {
      setVMDistro(availableVMVersions[0].short_name);
      setVMDistroVersion(availableVMVersions[0].versions[0])
      setKurlVersion(initialKurlVersion);
      setVMVersionsInitialized(true);
    }
  }, [availableVMVersions]);
  //  #endregion

  const versionOptions =
    kubernetesDistro && availableVersions
      ? availableVersions.find(({ short_name }) => short_name === kubernetesDistro)
          ?.versions || []
      : [{ value: "", label: "Loading..." }];
  
  const vmVersionOptions =
    vmDistro && availableVMVersions
      ? availableVMVersions.find(({ short_name }) => short_name === vmDistro) ?.versions || []
      : [{ value: "", label: "Loading..." }];

  const tagValues = tags
    ?.filter(tag => tag.key === tagKey && tag.values)
    ?.map(tag => tag.values)
    .flat();

  const maxNodes =
    kubernetesDistro && availableVersions
      ? availableVersions.find(({ short_name }) => short_name === kubernetesDistro)
          ?.nodes_max || 1
      : 1;
  
  const maxVMCount =
    vmDistro && availableVMVersions
      ? availableVMVersions.find(({ short_name }) => short_name === vmDistro)
          ?.nodes_max || 1
      : 1;

  const instanceTypeRef = useRef<HTMLInputElement>(null);
  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    dryRun?: boolean
  ) => {
    e.preventDefault();
    setHasAttemptedSubmit(false);
    resetCreateClusterMutation();

    if (
      !kubernetesDistro ||
      !nodeGroups[0].instance_type ||
      (kubernetesDistro !== "embedded-cluster" && !kubernetesVersion && !kurlVersion) ||
      (kubernetesDistro === "embedded-cluster" && !licenseId) ||
      !ttl ||
      !nodeGroups[0].node_count ||
      !nodeGroups[0].disk_gib
    ) {
      setHasAttemptedSubmit(true);
      if (!nodeGroups[0].instance_type) {
        instanceTypeRef.current?.focus();
      }
      return;
    }

    if (tagKey || tagValue) {
      setClusterTagError("Please save tag key and value before submitting");
      return;
    }

    const payload = {
      name: clusterName || "",
      kubernetes_distribution: kubernetesDistro,
      kubernetes_version:
        kubernetesDistro === "kurl"
          ? kurlVersion
          : kubernetesDistro === "embedded-cluster"
          ? channelSequence
          : kubernetesVersion,
      license_id: kubernetesDistro === "embedded-cluster" ? (licenseId || "") : "",
      instance_type: nodeGroups[0].instance_type,
      ttl,
      tags: tempTags,
      node_groups: nodeGroups,
      ip_family: ipFamily ? ipFamily : "ipv4"
    };

    if (dryRun) {
      estimateClusterCost(payload);
      return;
    }

    createCluster(payload);
  };

  const handleCreateVM = async (
    e: React.FormEvent<HTMLFormElement>,
    dryRun?: boolean
  ) => {
    e.preventDefault();
    const payload = {
      name: clusterName,
      distribution: vmDistro,
      version: vmDistroVersion,
      ttl: vmTtl,
      tags: tempTags,
      instance_type: vmConfig.instance_type,
      count: vmConfig.count,
      disk_gib: vmConfig.disk_gib,
    };

    if (dryRun) {
      estimateVMCost(payload);
      return;
    }

    createVM(payload);
  };

  let tempTagsString = "";
  (tempTags as Tags[]).forEach(item => {
    tempTagsString += ` --tag ${item.key}=${item.value}\n`;
  });
  const clusterCreateCommand = generateClusterCreateCommand(
    clusterName,
    kubernetesDistro,
    nodeGroups,
    licenseId,
    channelSequence,
    kurlVersion,
    kubernetesVersion,
    ttl,
    tempTagsString
  );

  const vmCreateCommand = generateVMCreateCommand(
    defaultVMInstanceType,
    vmDistro,
    vmDistroVersion,
    vmConfig,
    vmTtl,
    tempTagsString
  );

  const showInstanceTypeError = !nodeGroups[0].instance_type && hasAttemptedSubmit;
  const showLicenseIdError = kubernetesDistro == "embedded-cluster" && !licenseId && hasAttemptedSubmit;

  const distroOptions =
    kubernetesDistro && availableVersions
      ? availableVersions
          .map(({ short_name }) => {
            if (!instanceTypeDistros.includes(short_name)) {
              return;
            }
            const distro = distros.find(distro => distro.shortName === short_name);
            if (!distro) {
              return;
            }
            const distroStatus =
              kubernetesDistro &&
              availableVersions &&
              availableVersions.find(
                availableVersions => availableVersions.short_name === short_name
              )?.status;

            return {
              value: short_name,
              label: (
                <div>
                  <div className="tw-flex tw-items-center tw-justify-center">
                    <div className="tw-flex tw-items-center tw-p-1 tw-bg-white tw-rounded">
                      {distro.renderIconLarge()}
                    </div>
                  </div>
                  <div className="tw-mt-1 tw-text-center">{distro.label}</div>
                </div>
              ),
              disabled: distroStatus ? !distroStatus.enabled : false
            };
          })
          .flatMap(v => v || [])
      : [{ value: "", label: "Loading..." }];
  
  const vmDistroOptions =
    vmDistro && availableVMVersions
      ? availableVMVersions.map(({ short_name }) => {
        if (!vmInstanceTypeDistros.includes(short_name)) {
          return;
        }
        const distro = distros.find(distro => distro.shortName === short_name);
        if (!distro) {
          return;
        }
        const distroStatus =
          kubernetesDistro &&
          availableVersions &&
          availableVersions.find(
            availableVersions => availableVersions.short_name === short_name
          )?.status;

        return {
          value: short_name,
          label: (
            <div>
              <div className="tw-flex tw-items-center tw-justify-center">
                <div className="tw-flex tw-items-center tw-p-1 tw-bg-white tw-rounded">
                  {distro.renderIconLarge()}
                </div>
              </div>
              <div className="tw-mt-1 tw-text-center">{distro.label}</div>
            </div>
          ),
          disabled: distroStatus ? !distroStatus.enabled : false
        };
      })
      .flatMap(v => v || [])
  : [{ value: "", label: "Loading..." }];

  const distroStatus =
    kubernetesDistro &&
    availableVersions &&
    availableVersions.find(({ short_name }) => short_name === kubernetesDistro);
  
  const vmDistroStatus =
    vmDistro &&
    availableVMVersions &&
    availableVMVersions.find(({ short_name }) => short_name === vmDistro);

  const isDistroDisabled =
    vmDistroStatus && vmDistroStatus.short_name === kubernetesDistro
      ? distroStatus?.status?.enabled === false
      : false;
  // TODO: fix this once instance types is working in the api
  // const isVMDistroDisabled =
  //   vmDistroStatus && vmDistroStatus.short_name === vmDistro
  //     ? vmDistroStatus?.status?.enabled === false
  //     : false;
  const isVMDistroDisabled = false;

  return (
    <ClusterContext.Provider
      value={{
        addTag,
        availableInstanceTypes,
        availableVMInstanceTypes,
        availableVersions,
        channelSequence,
        clearForm,
        clusterCreateCommand,
        clusterId,
        clusterName,
        clusterTagError,
        tags,
        clusterTagValues: tagValues,
        createClusterError,
        createClusterLoading,
        estimateClusterCost,
        estimateClusterCostData,
        estimateClusterCostError,
        estimateClusterCostLoading,
        isEstimateClusterCostError,
        defaultInstanceType,
        diskSize,
        distroChanged,
        distroOptions,
        distroStatus,
        handleSubmit,
        hasAttemptedSubmit,
        hasSetDiskSize,
        hasSetNodeCount,
        initialKurlVersion,
        instanceType,
        instanceTypeDistros,
        instanceTypeRef,
        isAutoScaling,
        isCreateClusterError,
        isCreateNewTag,
        isDistroDisabled,
        isInputOpen,
        kubernetesDistro,
        kubernetesVersion,
        kurlVersion,
        licenseId,
        maxDiskSize,
        maxNodes,
        nodeGroups,
        ipFamily,
        removeTag,
        resetCreateClusterMutation,
        setChannelSequence,
        setClusterId,
        setClusterName,
        setClusterTagError,
        setDiskSize,
        setDistroChanged,
        setHasAttemptedSubmit,
        setHasSetDiskSize,
        setInstanceType,
        setIsAutoScaling,
        setIsCreateNewTag,
        setIsInputOpen,
        setKubernetesDistro,
        setKubernetesVersion,
        setKurlVersion,
        setLicenseId,
        setMaxDiskSize,
        setHasSetNodeCount,
        setNodeGroups,
        setVMConfig,
        vmConfig,
        setTagKey,
        setTagValue,
        setTempTags,
        setTtl,
        setVersionsInitialized,
        setIPFamily,
        showInstanceTypeError,
        showLicenseIdError,
        tagKey,
        tagValue,
        tempTags,
        ttl,
        useInstanceTypes,
        versionOptions,
        versionsInitialized,
        canHaveNodeGroups,
        vmId,
        vmTtl,
        setVMTtl,
        createVMError,
        createVMLoading,
        estimateVMCostData,
        estimateVMCostError,
        estimateVMCostLoading,
        isEstimateVMCostError,
        clearVMForm,
        handleCreateVM,
        estimateVMCost,
        vmDistroOptions,
        availableVMVersions,
        setVMId,
        vmCreateCommand,
        vmDistroStatus,
        isVMDistroDisabled,
        setVMDistro,
        vmDistroVersion,
        setVMDistroVersion,
        vmVersionOptions,
        vmDistro,
        maxVMCount,
        isCreateVMError
      }}
    >
      {children}
    </ClusterContext.Provider>
  );
};

export const useCluster = () => {
  const context = useContext(ClusterContext);
  if (!context) {
    throw new Error("useCluster must be used within a ClusterProvider");
  }
  return context;
};

export function generateClusterCreateCommand(
  clusterName: string,
  kubernetesDistro: string,
  nodeGroups: NodeGroup[],
  licenseId?: string,
  channelSequence?: string,
  kurlVersion?: string,
  kubernetesVersion?: string,
  ttl?: string,
  tempTagsString?: string
): string {
  // prettier-ignore
  return `replicated cluster create${
    clusterName
      ? ` --name ${clusterName.indexOf(` `) !== -1 ? `"${clusterName}"` : clusterName}`
      : ``
  } --distribution ${kubernetesDistro
  }${nodeGroups?.length > 1 ? `${nodeGroups.map((ng) => 
    ` --nodegroup instance-type=${ng.instance_type},nodes=${ng.node_count},disk=${ng.disk_gib}`).join(``)}`
  : ` --instance-type ${nodeGroups[0].instance_type} --disk ${nodeGroups[0].disk_gib}${nodeGroups[0].node_count > 1 ? ` --nodes ${nodeGroups[0].node_count}` : ''}`
  }${
    kubernetesDistro === "embedded-cluster" ? ` --license-id ${licenseId || "[REQUIRED]"}` : ''}${
      kubernetesDistro === "embedded-cluster"
      ? ( channelSequence ? ` --version ${channelSequence}` : `` )
      : ` --version ${kubernetesDistro === "kurl" ? kurlVersion : kubernetesVersion}`
  }${
    ttl !== "1h" ? ` --ttl ${ttl}` : ``
  }${tempTagsString || ''}`;
}

function generateVMCreateCommand(
  defaultVMInstanceType?: string,
  vmDistro?: string,
  vmDistroVersion?: string,
  vmConfig?: {
    instance_type: string;
    disk_gib: number;
    count: number;
  },
  ttl?: string,
  tempTagsString?: string
): string {
  // prettier-ignore
  return `replicated vm create --distribution ${vmDistro} --version ${vmDistroVersion}${
    vmConfig.count > 1 ? ` --count ${vmConfig.count}` : ""
  } --instance-type ${
    vmConfig.instance_type || defaultVMInstanceType
  } --disk ${vmConfig.disk_gib}${
    ttl !== "1h" ? ` --ttl ${ttl}` : ``
  }${tempTagsString || ''}`;
};
